<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popup-component="popupComponent"
        title="Admin List"
        icon="mdi-handshake"
        addButtonText="Admin"
        stateendpoint="adminUsers"
        :actions="actions"
        :basic-data="basicData"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import AdminPopup from "../components/popups/AdminPopup";

export default {
  name: 'Admins',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Admin List | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, AdminPopup},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'email',
          value: 'email'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'name'},
        {value: 'email'},
        {value: 'isActive'},
        {value: 'action'}
      ],
      actions: {
        load: 'loadAdminUsers',
        create: 'register',
        update: 'updateAdmin',
        remove: 'removeUser',
        destroy: 'destroyUser',
      },
      basicData: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        role: '2',
        isActive: true
      }
    }
  },
  computed: {
    popupComponent() {
      return AdminPopup;
    }
  },
}
</script>
