<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid || isSiteValid"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-row class="ml-0 mt-3 mb-0" no-gutters>
        <v-col cols="12">
          <v-text-field
              color="deep-purple accent-4"
              v-model="currentItem.name"
              :rules="requiredFieldRule"
              label="Full Name"
              prepend-icon="create"
              required
              dense
              outlined
          />
          <v-text-field
              color="deep-purple accent-4 site-field"
              v-model="currentItem.email"
              :rules="requiredEmailRule"
              label="Email"
              prepend-icon="mdi-email"
              type="email"
              required
              dense
              outlined
              @input="handleSearch(currentItem.email)"
              :messages=siteError
          />
          <div v-if="!isEditMode">
            <v-text-field
                color="deep-purple accent-4"
                label="Password"
                prepend-icon="vpn_key"
                v-model="currentItem.password"
                :rules="[rules.required, rules.min]"
                type="password"
                outlined
                dense
                required
            />
            <v-text-field
                color="deep-purple accent-4"
                prepend-icon="mdi-onepassword"
                :rules="adminPasswordConfirmationRules"
                v-model="currentItem.passwordConfirmation"
                label="Confirm Password"
                type="password"
                dense
                outlined
                required
            />
          </div>
          <v-row class="custom-switch-btn">
            <v-col xs="3">
              <v-subheader class="switch-btn-title">Status</v-subheader>
            </v-col>
            <v-col xs="8">
              <v-switch
                  v-model="currentItem.isActive"
                  color="deep-purple accent-4"
                  dense
                  inset
                  prefix="Status">
              </v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import Api from "../../services/Api";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";

export default {
  name: 'AdminPopup',
  components: {BasePopup},
  mixins: [PopupMixin],
  data() {
    return {
      term$: new Subject(),
      isSiteValid: false,
      siteError: ''
    }
  },
  mounted() {
    this
        .term$
        .pipe(
            //filter(term => term && term.length > 2),
            debounceTime(500),
            distinctUntilChanged()
        )
        .subscribe(term => this.apiSearch(term));
  },
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Admin'
          : 'Add Admin'
    }
  },
  methods: {
    handleSearch(term) {
      this.term$.next(term);
    },
    apiSearch(term) {
      let id = null;
      if(this.isEditMode) {
        id = this.currentItem.id
      }
      const path = `/admin-by-email/${term || ''}/${id}`;
      this.siteError = '';
      Api.getRequest({endpoint: path})
          .then(({data}) => {
            this.isSiteValid = data.isSiteValid;
            if(this.isSiteValid) {
              this.siteError = 'This email is already exists!'
            }
          })
          .catch((err) => {
            console.log(err)
          })
    }
  }
}
</script>
